import { EditDataType, EditJob } from '@ht-lib/accounts-models'
import { imagesByAimsJobSnap, imagesLongRefsSnap, imagesRefsSnap, OnImages, Unlisten } from '@ht-lib/image-util'

export function splitAimsJobNumber (aimsJobNumber: string): [string, string] {
  const isHyphen = aimsJobNumber.includes('-')
  const [accountCode, jobCode] = isHyphen ? aimsJobNumber.split('-') : aimsJobNumber.split('/')
  return [accountCode, jobCode]
}

export function getImagesForEditJob (job: EditJob, onImages: OnImages): Unlisten {
  switch (job.dataType) {
    case EditDataType.LONGREFS: {
      return imagesLongRefsSnap(job.data as string[], onImages)
    }
    case EditDataType.AIMSJOB: {
      const [accountCode, jobCode] = splitAimsJobNumber(job.data as string)
      return imagesByAimsJobSnap(accountCode, jobCode, onImages)
    }
    case EditDataType.REFS: {
      const refs = job.data as string[]
      return imagesRefsSnap(refs.map(ref => ({ market: job.market, ref })), onImages)
    }
  }
}

import { UserProfile, UserRoles } from '@ht-lib/accounts-models'
import type firebase from 'firebase/compat/app'
import Vue from 'vue'
import { app } from '../firebase'

export interface UserProfileWithId extends UserProfile{
  id: string
}
export interface UserProfileMap { [id: string]: UserProfileWithId }
const usersColl = app.firestore().collection('userProfiles') as firebase.firestore.CollectionReference<UserProfile>
const users: UserProfileMap = Vue.observable({})
export async function getUserWithStore (id: string) {
  if (!users[id]) {
    const snap = await usersColl.doc(id).get()
    const usr = snap.data()
    users[id] = { ...usr, id }
  }

  return users[id]
}

const hasEditing = false
export async function getImageEditingUsers () {
  if (!hasEditing) {
    const snap = await usersColl.where('roles', 'array-contains-any', [UserRoles.IMAGE_EDITING, UserRoles.IMAGE_EDITING_ADMIN]).get()
    snap.docs.forEach(doc => {
      users[doc.id] = { ...doc.data(), id: doc.id }
    })
  }

  return Object.values(users).filter(x => x.roles.some(x => x === UserRoles.IMAGE_EDITING || x === UserRoles.IMAGE_EDITING_ADMIN))
}
